import { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../redux/hooks';
//import initOnBoardingTour from '../utils/initOnboardingTour';
import { Avatar, Dropdown, Navbar, Sidebar } from 'flowbite-react';
import { IoMdNotifications } from 'react-icons/io';

export const TrackerLayout = () => {
  const { user } = useTypedSelector((state) => state.auth);

  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    (function () {
      (function c(d, w, m, i) {
        (window as any).supportAPIMethod = m;
        const s = d.createElement('script');
        s.id = 'supportScript';
        const id = '7d14570d6c8dd266c07de304d94c61ac';
        s.src =
          (!i
            ? 'https://admin.verbox.ru/support/support.js'
            : 'https://static.site-chat.me/support/support.int.js') +
          '?h=' +
          id;
        s.onerror = i
          ? undefined
          : function () {
              c(d, w, m, true);
            };
        w[m] = w[m]
          ? w[m]
          : function () {
              (w[m].q = w[m].q ? w[m].q : []).push(arguments);
            };
        (d.head ? d.head : d.body).appendChild(s);
      })(document, window, 'Verbox');
    })();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    (window as any).Verbox('setClientInfo', {
      email: user.username,
      name: user.name,
    });
  }, [user]);

  return (
    <>
      <Navbar
        fluid
        rounded
        theme={{ base: 'bg-white shadow-sm px-6 sticky top-0 z-10' }}>
        <Navbar.Brand
          href={`${window.location.origin}/account`}
          className="h-16">
          <img
            alt="Sellerator Logo"
            className="mr-3 h-16 sm:h-16"
            src="/logo.jpg"
          />
        </Navbar.Brand>
        <div className="flex md:order-2 items-center h-16 gap-8">
          <IoMdNotifications size={24} />
          <Dropdown
            inline
            label={
              <div className="flex items-center gap-4 z-10">
                <span>{user?.name}</span>
                <Avatar
                  alt="User settings"
                  img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                  rounded
                />
              </div>
            }>
            <Dropdown.Header>
              <span className="block text-sm">{user?.name}</span>
              <span className="block truncate text-sm font-medium">
                {user?.username}
              </span>
            </Dropdown.Header>
            <Dropdown.Item onClick={() => {}}>Выход</Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
      </Navbar>

      <Sidebar
        collapsed={!open}
        className={'fixed top-16 left-0 shadow-md z-10'}></Sidebar>
      <main className={`p-8 ${open ? 'ml-64' : 'ml-16'}`}>
        <Outlet />
      </main>
    </>
  );
};
