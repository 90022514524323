import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';
import ProtectedRoute from './ProtectedRoute';
import Layout from './layout/Layout';
import { store } from './redux/store';
import Account from './views/account';
import SignInView from './views/auth/SignInView';
import SignUpView from './views/auth/SignUpView';
import AllDiscountCampaignView from './views/discount_campaign/AllDiscountCampaignView';
import DiscountCampaignEditView from './views/discount_campaign/DiscountCampaignEditView';
import AllProductUnitEconomicResearchView from './views/product_unit_economic_research/AllProductUnitEconomicResearchView';
import PaymentSuccessView from './views/tariff/PaymentSuccessView';
import SelectTariffView from './views/tariff/SelectTariffView';
import { useEffect } from 'react';
import YandexTokenPage from './views/auth/yandex/YandexTokenPage';
import VkTokenPage from './views/auth/vk/VkTokenPage';
import AllProcurementView from './views/procurement/AllProcurementView';
import ProcurementEditView from './views/procurement/ProcurementEditView';
import { Wizard } from './views/tracker/project/wizard';
import { TrackerLayout } from './layout/TrackerLayout';
import { AddValues } from './views/tracker/metric/add-values';

function App() {
  //initialise metrics
  useEffect(() => {
    (window as any).ym(process.env.REACT_APP_YANDEX_METRICA_KEY, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ut: 'noindex',
    });

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_KEY}`; // whatever url you want here
    script.charset = 'utf-8';
    script.async = true;
    script.onload = () => {
      (window as any).dataLayer =
        ((window as any).dataLayer as Array<any>) || [];
      function gtag(...args: any) {
        (window as any).dataLayer.push(arguments);
      }
      gtag?.('js', new Date());

      gtag?.('config', process.env.REACT_APP_GTAG_KEY);
    };
    document.head.appendChild(script);
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/account" />} />
          <Route path="signin" element={<SignInView />} />
          <Route path="auth">
            <Route path="yandex-oauth-token" element={<YandexTokenPage />} />
            <Route path="vk-oauth-token" element={<VkTokenPage />} />
          </Route>
          <Route path="signup" element={<SignUpView />} />
          <Route path="account" element={<ProtectedRoute />}>
            <Route path="" element={<Layout />}>
              <Route path="" element={<Account />} />
              {/* Discount Campaign */}
              <Route
                path="discount-campaigns"
                element={<AllDiscountCampaignView />}
              />
              <Route
                path="discount-campaigns/:discountCampaignId"
                element={<DiscountCampaignEditView />}
              />

              {/* Economy calculation */}
              <Route
                path="unit-economy"
                element={<AllProductUnitEconomicResearchView />}
              />

              {/*Procurement */}
              <Route path="procurement" element={<AllProcurementView />} />
              <Route
                path="procurement/:procurementId"
                element={<ProcurementEditView />}
              />

              {/* Tariff plans */}
              <Route path="tariffs" element={<SelectTariffView />} />
              <Route
                path="tariffs/payment-success"
                element={<PaymentSuccessView />}
              />
            </Route>
          </Route>
          <Route path="tracker">
            <Route path={'metric'} Component={TrackerLayout}>
              <Route path={'add-values'} Component={AddValues} />
            </Route>
            <Route path={'project'} Component={TrackerLayout}>
              <Route path={'wizard'} Component={Wizard} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <RouterProvider router={router} /> */}
    </Provider>
  );
}
export default App;
