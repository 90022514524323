import React from 'react';
import { Button, Table, TextInput } from 'flowbite-react';
import { MdAdd } from 'react-icons/md';

const defaultMetrics = [
  { name: 'Показы', assignee: '@' },
  { name: 'Расход, руб', assignee: '@' },
  { name: 'Кликов', assignee: '@' },
  { name: 'Лидов', assignee: '@' },
];
export const AddValues = () => {
  const [metrics, setMetrics] = React.useState(defaultMetrics);
  return (
    <div className={'flex w-[320px]  flex-col  justify-center'}>
      <h1 className={'font-bold mb-1.5 text-lg text-center'}>
        Контекстная реклама для Газпром
      </h1>
      <Table className={''}>
        <Table.Head>
          <Table.HeadCell className={'w-1/2'}>Показатель</Table.HeadCell>
          <Table.HeadCell className={'w-1/2'}>Значение</Table.HeadCell>
        </Table.Head>

        <Table.Body>
          {metrics.map((metric, index) => (
            <Table.Row key={index}>
              <Table.Cell className={'w-1/2'}>{metric.name}</Table.Cell>
              <Table.Cell className={'w-1/2'}>
                <TextInput />
              </Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell className={'w-1/2'} colSpan={2}>
              <Button>
                <MdAdd className="mr-2 h-5 w-5" />
                Отправить
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
