import { Button, Label, Table, TextInput } from 'flowbite-react';
import React from 'react';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';

const defaultMetrics = [
  { name: 'Показы', assignee: '@' },
  { name: 'Расход, руб', assignee: '@' },
  { name: 'Кликов', assignee: '@' },
  { name: 'Лидов', assignee: '@' },
];
export const Wizard = () => {
  const [metrics, setMetrics] = React.useState(defaultMetrics);
  return (
    <div className={'max-w-3xl border-2 p-4 gap-6 flex flex-col'}>
      <h1 className={'font-bold mb-1.5 text-xl'}>Новый проект</h1>
      <div>
        <Label className={'font-bold'}>Проект</Label>
        <TextInput />
      </div>
      <div className={'flex flex-col  justify-center'}>
        <Label className={'font-bold mb-1.5'}>Показатели</Label>
        <Table className={'flex-1'}>
          <Table.Head>
            <Table.HeadCell>Показатель</Table.HeadCell>
            <Table.HeadCell>Ответственный</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>

          <Table.Body>
            {metrics.map((metric, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <div className={'flex flex-row items-center gap-2'}>
                    {metric.name}
                    <MdEdit />
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <TextInput value={metric.assignee} />
                </Table.Cell>
                <Table.Cell>
                  <div className={'flex items-center gap-2'}>
                    <Button color={'gray'}>Пригласить</Button>
                    <MdDelete className="w-5 h-5 text-red-600" />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell>
                <Button color="gray">
                  <MdAdd className="mr-2 h-5 w-5" />
                  Добавить
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div>
        <Button>Сохранить</Button>
      </div>
    </div>
  );
};
